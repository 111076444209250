<!-- 招生系统基础配置 -- 中考考试科目管理 -- 新增 -->
<template>
	<edit-template class="form" @confirm="submit" @cancel="$confirm('取消编辑?','提示',{type:'warning'}).then(()=>$router.back()).catch(()=>{});" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
		<el-form ref="form" :model="form" :rules="rules" label-width="150rem" style="margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
			<el-form-item label="校区" prop="school_id">
				<el-select v-model="form.school_id" placeholder="请选择校区">
					<el-option v-for="item in schoolArr" :label="item.school_name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="档位名称" prop="rank_name">
				<el-select v-model="form.rank_name" placeholder="请选择档位名称">
					<el-option v-for="(item) in rankNameArr" :label="item" :value="item"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="金额" prop="money">
				<el-input v-model="form.money" maxlength="10" placeholder="请输入金额"></el-input>
			</el-form-item>
		</el-form>
		
	
</edit-template>
</template>

<script>

import {school} from "@/api/account-management";

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
		return {
			form: {
				school_id: '',
				rank_name: '',
				money: '',
			},
			rules: {
				school_id: [{required: true, message: "请选择校区"}],
				rank_name: [{required: true, message: "请选择档位名称"}],
				money: [{required: true, message: "请输入金额"}],
			},
			schoolArr: [],
			rankNameArr: [],
		}
	},
	created() {
		this.$_register('api/recruit/common/consulting-school').then(res => {
			this.schoolArr = res.data.data.entity_school
		})
		this.$_register.get('api/recruit/examination-rank/get-rank').then(res => {
			this.rankNameArr = res.data.data
		})
		this.$_register.get('api/recruit/rank-tuition/show', {params: {id: this.$route.query.id}}).then(res => {
			this.form = res.data.data
		})
	},
	methods: {
		school,
		// 提交数据
		submit() {
			this.$refs.form.validate((res) => {
				if (res) {
					this.$_register.post("/api/recruit/rank-tuition/edit\n", this.form).then(res => {
						this.$message({
							type: 'success',
							message: res.data.msg,
							onClose: () => {
								this.$router.back();
							}
						});
					})
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.form {
	padding-top: 24rem;
}
</style>
